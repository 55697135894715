import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

const firebaseConfig = {
    apiKey: "AIzaSyANRa3llLjtPhwI4u01dKssH-dmHtqQ7bk",
    authDomain: "orane-alpha.firebaseapp.com",
    projectId: "orane-alpha",
    storageBucket: "orane-alpha.appspot.com",
    messagingSenderId: "901455189379",
    appId: "1:901455189379:web:3c1fa3090d4720657d92b6"
};

firebase.initializeApp(firebaseConfig);

export default firebase;