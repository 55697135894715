import React, {useState, useEffect} from "react";
import ReactPlayer from "react-player";
import firebase from '../../firebaseConfig';
import './Player.css';

export const Player = ({ url }) => {
    const [videoUrl, setVideoUrl] = useState(null);

    useEffect(() => {
        const storageRef = firebase.storage().refFromURL(url);
        storageRef.getDownloadURL().then(downloadUrl => {
            setVideoUrl(downloadUrl);
        });
    }, [url]);

    return (
        <div className="artiste-player-page flex column justifyCenter center">
            <div className="movie-played-holder-actor">
                <div className="movie-played">
                    <ReactPlayer
                        controls={true}
                        width='100%'
                        height='100%'
                        url={videoUrl}
                        className="movie-player"
                    />
                </div>
            </div>
        </div>
    );
};