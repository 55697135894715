import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import { Home } from "./component/Home/Home";
import { Player } from "./component/Player/Player";

const URL =
  "https://firebasestorage.googleapis.com/v0/b/orane-alpha.appspot.com/o/";

export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/adrian-de-villebonne"
          element={
            <Player
              url={`${URL}adrian-de-villebonne-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/alexis-mchaik"
          element={
            <Player
              url={`${URL}alexis-mchaik-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/anouchka-enten"
          element={
            <Player
              url={`${URL}anouchka-enten-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/ariane-ducasse"
          element={
            <Player
              url={`${URL}ariane-ducasse-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/emma-mouthon"
          element={
            <Player
              url={`${URL}emma-mouthon-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/jade-casagrande"
          element={
            <Player
              url={`${URL}jade-casagrande-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/julia-chiche"
          element={
            <Player
              url={`${URL}julia-chiche-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/kenza-fairouze"
          element={
            <Player
              url={`${URL}kenza-fairouze-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/lea-gouzy"
          element={
            <Player url={`${URL}lea-gouzy-lesfilmsdelabande.mp4?alt=media`} />
          }
        />
        <Route
          path="/lea-huiban"
          element={
            <Player url={`${URL}lea-huiban-lesfilmsdelabande.mp4?alt=media`} />
          }
        />
        <Route
          path="/lucille-guillaume"
          element={
            <Player
              url={`${URL}lucille-guillaume-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/marie-prenant-mamoune-kettani"
          element={
            <Player
              url={`${URL}marie-prenant-mamoune-kettani-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/mayer-ramouki"
          element={
            <Player
              url={`${URL}mayer-ramouki-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/pierre-lahmi"
          element={
            <Player
              url={`${URL}pierre-lahmi-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/sebastien-lassale"
          element={
            <Player
              url={`${URL}sebastien-lassale-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/victoire-vieyra"
          element={
            <Player
              url={`${URL}victoire-vieyra-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/marie-prenant"
          element={
            <Player
              url={`${URL}marie-prenant-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/maena-krotenberg"
          element={
            <Player
              url={`${URL}maena-krotenberg-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/margaux-leleu"
          element={
            <Player
              url={`${URL}margaux-leleu-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/lauryanne-philippe"
          element={
            <Player
              url={`${URL}lauryanne-philippe-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/chloe-delassis"
          element={
            <Player
              url={`${URL}chloé-delassis-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/david-azria"
          element={
            <Player url={`${URL}david-azria-lesfilmsdelabande.mp4?alt=media`} />
          }
        />
        <Route
          path="/natacha-vives-antoine-boizeau"
          element={
            <Player
              url={`${URL}natacha-vives-antoine-boizeau-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/benjamin-bensoussan"
          element={
            <Player
              url={`${URL}benjamin-bensoussan-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/marianella-guivar"
          element={
            <Player
              url={`${URL}marianella-guivar-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/sabrina-ouldhammouda"
          element={
            <Player
              url={`${URL}sabrina-ouldhammouda-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/sophie-ouaknine"
          element={
            <Player
              url={`${URL}sophie-ouaknine-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route
          path="/prescilla-elkhoury"
          element={
            <Player
              url={`${URL}prescilla-elkhoury-lesfilmsdelabande.mp4?alt=media`}
            />
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
